import { useRef, useState, useEffect, useCallback } from 'react'
// import { easing } from 'maath'
import { useFrame, useThree } from '@react-three/fiber'
import { Text } from '@react-three/drei'
import * as THREE from 'three'
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { suspend } from 'suspend-react'
import Link from '../Link/Link'
import { Select } from "@react-three/postprocessing"
import { debounce } from "lodash"

import { useAnimation } from '../AnimationContext/AnimationContext';
// lets find the color!
// import { useControls } from 'leva'
  
// import { regular } from './App.css'
const bold = import('../../assets/HelveticaNowDisplayXBd.ttf')
const regular = import('../../assets/HelveticaNowDisplay.ttf')

// function KeyLight({ brightness, color }) {
//   return (
//     <rectAreaLight
//       width={3}
//       height={3}
//       color={color}
//       intensity={brightness}
//       position={[-2, 0, 5]}
//       lookAt={[0, 5, 0]}
//       penumbra={1}
//       castShadow
//     />
//   );
// }
// function FillLight({ brightness, color }) {
//   return (
//     <rectAreaLight
//       width={3}
//       height={3}
//       intensity={brightness}
//       color={color}
//       position={[2, 1, 4]}
//       lookAt={[0, 5, 0]}
//       penumbra={2}
//       castShadow
//     />
//   );
// }

// function RimLight({ brightness, color }) {
//   return (
//     <rectAreaLight
//       width={2}
//       height={2}
//       intensity={brightness}
//       color={color}
//       position={[1, 4, -2]}
//       rotation={[0, 180, 0]}
//       castShadow
//     />
//   );
// }

function Hex({geometry, material, children, ...props}) {
  // Hover state
  const [hovered, hover] = useState(null)

  const [isAnimLocal, setIsAnimLocal] = useState(false)
  // Debounce hover a bit to stop the ticker from being erratic
  const debouncedHover = useCallback(debounce(hover, 30), [])
  const over = (name) => (e) => (e.eventObject.visible === true ? debouncedHover(name) : '')
  const active = props.active
  const anim = props.anim
  const isAnim = props.isAnim
  const prev = props.prev
  const topCtx = props.topCtx
  const scene = props.scene
  const direction = props.direction
  const component = useRef();
  const context = useRef();
  const activeRef = useRef(false);
  const level = props.level
  const isLevel= props.isLevel
  const prevActive = props.prevActive
  const isActive = props.isActive
  const title = props.title 
  const text = props.text 
  const textColor = props.textColor
  const textPosition = props.textPosition
  const titlePosition = props.titlePosition
  const fontSize = props.fontSize
  const intensity = props.intensity

  const { isAnimating, setIsAnimating } = useAnimation();



  gsap.registerPlugin(useGSAP);

  function copyMaterial(mesh) {
    if (mesh.material !== undefined) {
      let prevMaterial = mesh.material; 
      let material
      if (mesh.material.type === 'MeshPhysicalMaterial') {
        material = new THREE.MeshPhysicalMaterial();          
        THREE.MeshPhysicalMaterial.prototype.copy.call( material, prevMaterial );
      } else {
        material = new THREE.MeshBasicMaterial();          
        THREE.MeshBasicMaterial.prototype.copy.call( material, prevMaterial );
      }
      return material
    }    
  }
  const { raycaster } = useThree();

  // useEffect(() => {
    raycaster.params.Points.threshold = 0.2;
  // }, [threshold]);

  // useEffect(() => {
  //     console.log(isAnim)
  // }, [isAnim]);

  // useLayoutEffect(() => {
    let ctx = gsap.context((self) => {
      let tl = gsap.timeline({
        autoRemoveChildren: false,
        smoothChildTiming: true,
        paused: true,
        onStart: function() {
          // console.log('starting hex tl animations!');
          // anim(true)
          // setPointerEvents('none');
          setIsAnimating(true);
          
        },
        onReverseComplete: function() {
          // console.log('completed reversed hex tl animations!');
          setIsAnimating(false);
        },
        onComplete: function() {
          // console.log('completed hex tl animations!');
          // anim(false)
          setIsAnimating(false);
          // setPointerEvents('auto');
        }
      });

      let smDur = 0.35
      let mdDur = 0.45
      // let lgDur2 = 0.6
      let lgDur = 0.7
      let xlDur = 1
      let dl = 0.6
      tl.addLabel('active')


      self.add("playTimeline", () => { 
        if (component.current.isGroup) {
          tl.to(component.current.position, {
            x: 0,
            z: 0,
            y: -13.8,
            duration: 0.8,
            ease: "back.in",
          },'active')
          if (component.current.name !== 'active-platform') {
            tl.to(component.current.scale, {
              x: '+=0.2',
              z: '+=0.2',
              duration: 0.75,
            }, 'active')
          }
        }
        if (component.current.isGroup && props.dest === '02' && props.isDirection === 'down') {  
            let hexMesh = component.current.children[0].children[0];  
            component.current.children[0].children.forEach((mesh) => {
              if (mesh.isMesh) {
                mesh.material = copyMaterial(mesh)
                mesh.material.transparent = true;
                if (component.current.name === 'active-platform') {
                  // console.log('let\'s hide our hex during the transition!')
                  tl
                    .to(mesh.material, {
                      opacity: 0,
                      duration: smDur,
                      onUpdate: function() {
                        if (mesh.material.opacity < 1) {
                          mesh.material.depthTest = false;
                          gsap.set(mesh.material, {transparent: true})
                        } else {
                          mesh.material.depthTest = true;
                        }
                        if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                          hexMesh.visible = true;
                          mesh.material.visible = true
                        } else {
                          hexMesh.visible = false;
                          mesh.material.visible = false
                        }
                      } 
                    }, 'active')
                    .to(mesh.material, {
                      opacity: 1,
                      duration: 0.1,
                      delay: xlDur,
                      onUpdate: function() {
                        if (mesh.material.opacity < 1) {
                          mesh.material.depthTest = false;
                          gsap.set(mesh.material, {transparent: true})
                        } else {
                          mesh.material.depthTest = true;
                        }
                        if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                          mesh.material.visible = true
                          hexMesh.visible = true;
                        } else {
                          mesh.material.visible = false
                          hexMesh.visible = false;
                        }
                      } 
                    }, 'active+=' + mdDur)
                }
                mesh.children.forEach((mesh) => {
                  if ((component.current.name === 'active-platform' && mesh.name === 'title') || (component.current.name === 'active-platform' && mesh.name === 'logo' )) {
                    // console.log('let\'s hide our hex during the transition!')
                    mesh.material = copyMaterial(mesh)
                    gsap.set(mesh.material, {transparent: true})
                    tl
                      .to(mesh.material, {
                        opacity: 0,
                        duration: smDur,
                        onUpdate: function() {
                          if (mesh.material.opacity < 1) {
                            mesh.material.depthTest = false;
                            gsap.set(mesh.material, {transparent: true})
                          } else {
                            mesh.material.depthTest = true;
                          }
                          if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                            mesh.material.visible = true
                          } else {
                            mesh.material.visible = false
                          }
                        } 
                      }, 'active')
                      .to(mesh.material, {
                        opacity: 1,
                        duration: smDur,
                        delay: xlDur,
                        onUpdate: function() {
                          if (mesh.material.opacity < 1) {
                            mesh.material.depthTest = false;
                            gsap.set(mesh.material, {transparent: true})
                          } else {
                            mesh.material.depthTest = true;
                          }
                          if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                            mesh.material.visible = true
                          } else {
                            mesh.material.visible = false
                          }
                        } 
                      }, 'active+=' + mdDur)
                  }

                  if ((mesh.name !== 'title' && mesh.name !== 'logo' && mesh.name !== 'learn-more' )) {
                    // hides the hex/line/list on top level
                    mesh.material = copyMaterial(mesh)
                    gsap.set(mesh.material, {transparent: true})
                    tl
                    .to(mesh.material, {
                      opacity: 0,
                      duration: smDur,
                      onUpdate: function() {
                        if (mesh.material.opacity < 1) {
                          mesh.material.depthTest = false;
                          gsap.set(mesh.material, {transparent: true})
                        } else {
                          mesh.material.depthTest = true;
                        }
                        if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                          mesh.material.visible = true
                        } else {
                          mesh.material.visible = false
                        }
                      } 
                    }, 'active')
                  } else if (component.current.name === 'active-platform') {
                    // Title Movement (active platform)
                    tl
                    .to(mesh.position, { 
                      z: '+=0.85',
                      duration: lgDur              
                    }, 'active+=0.1')
                  } else {
                    // Title Movement
                    tl
                    .to(mesh.position, { 
                      z: '+=1.2',
                      x: '+=0.09',
                      duration: lgDur              
                    }, 'active+=0.1')
                    .to(mesh.scale, {
                      x: '+=0.08',
                      z: '+=0.08',
                      duration: lgDur,
                    }, 'active+=0.15')
                  }
                })
              }
          }) // end component.current.children.forEach
        }
        if (component.current.isGroup && props.dest === '03' && props.isDirection === 'down') {
          component.current.children[0].children[0].children.forEach((mesh) => {
            if (mesh.isGroup && mesh.name === 'learn-more') {
              let link = mesh;
              mesh.children[0].children.forEach((mesh) => {
                if (mesh.isMesh) {
                  tl
                  .to(mesh.material, {
                    duration: smDur,
                    delay: smDur,    
                    onUpdate: function() {
                      if (mesh.material.opacity < 1) {
                        mesh.material.depthTest = false;
                        gsap.set(mesh.material, {transparent: true})
                      } else {
                        mesh.material.depthTest = true;
                      }
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        link.visible = true;
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                        link.visible = false;
                      }
                    }                              
                  }, 'active+=' + smDur)
                }
              })
            }
            if (mesh.isMesh && mesh.material.name === "orange_tile.001") {
              mesh.material = copyMaterial(mesh)
              mesh.material.transparent = true;
              tl
              .to(mesh.material, { 
              opacity: 0,
              duration: smDur,
              onUpdate: function() {
                if (mesh.material.opacity < 1) {
                  mesh.material.depthTest = false;
                  gsap.set(mesh.material, {transparent: true})
                } else {
                  mesh.material.depthTest = true;
                }
                if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                  mesh.material.visible = true
                } else {
                  mesh.material.visible = false
                }
              }       
              },'active')
            }
          })
        }
        tl.play()  
      })
      self.add("hidePrevious", () => {
        let hexMesh = component.current.children[0].children[0];
        component.current.children[0].children.forEach((mesh) => {
          if (mesh.isMesh && mesh.name !== 'learn-more') {
            mesh.material = copyMaterial(mesh)
            gsap
              .to(mesh.material, { 
              opacity: 0,    
              onUpdate: function() {
                if (mesh.material.opacity < 1) {
                  mesh.material.depthTest = false;
                  gsap.set(mesh.material, {transparent: true})
                } else {
                  mesh.material.depthTest = true;
                }
                if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                  hexMesh.visible = true;
                  mesh.material.visible = true
                } else {
                  mesh.material.visible = false
                  hexMesh.visible = false;
                }
              },       
              duration: mdDur,     
            })  
            gsap
              .to(mesh.position, { 
                y: '-=18',
                duration: 0,        
                delay: mdDur,        
              })          
            mesh.children.forEach((mesh) => {
              if (mesh.isMesh && mesh.name !== 'learn-more') {
                mesh.material = copyMaterial(mesh)
                gsap
                .to(mesh.material, {
                  opacity: 0,    
                  onUpdate: function() {
                    if (mesh.material.opacity < 1) {
                      mesh.material.depthTest = false;
                      gsap.set(mesh.material, {transparent: true})
                    } else {
                      mesh.material.depthTest = true;
                    }
                  },  
                  duration: mdDur,        
                })
              }
            })
          }
        })
      });
      self.add("hidePreviousTop", () => {
        let hexMesh = component.current.children[0].children[0];
        component.current.children[0].children.forEach((mesh) => {
          if (mesh.isMesh && mesh.name !== 'learn-more') {
            mesh.material = copyMaterial(mesh)
            gsap
              .to(mesh.material, { 
              opacity: 0,    
              onUpdate: function() {
                if (mesh.material.opacity < 1) {
                  mesh.material.depthTest = false;
                  gsap.set(mesh.material, {transparent: true})
                } else {
                  mesh.material.depthTest = true;
                }
                if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                  mesh.material.visible = true
                  hexMesh.visible = true;
                } else {
                  mesh.material.visible = false
                  hexMesh.visible = false;
                }
              },   
              duration: mdDur,
              })  
            gsap
              .to(mesh.position, { 
                y: '-=18',
                duration: 0,        
                delay: mdDur,        
              })            
            mesh.children.forEach((mesh) => {
              if ((mesh.name === 'title' || mesh.name === 'logo') && mesh.name !== 'learn-more') {
                mesh.material = copyMaterial(mesh)
                gsap
                .to(mesh.material, {
                  opacity: 0,
                  duration: mdDur, 
                  onUpdate: function() {
                    if (mesh.material.opacity < 1) {
                      mesh.material.depthTest = false;
                      mesh.material.transparent = true;
                    } else {
                      mesh.material.depthTest = true;
                      mesh.material.transparent = false;
                    }
                    if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                      mesh.material.visible = true
                    } else {
                      mesh.material.visible = false
                    }
                    
                  }        
                })
              }
            })
          }
        })
      });
      // shows the previously hidden hex when going up a level
      self.add("showPrevious", () => {
        gsap.delayedCall(0.35, function(){  
          let hexMesh = component.current.children[0].children[0];
          component.current.children[0].children.forEach((mesh) => {
            if (mesh.isMesh && mesh.name !== 'learn-more') {
              mesh.material = copyMaterial(mesh)
              gsap
              .to(mesh.position, { 
                y: '+=18',
                duration: 0,             
              }) 
              gsap
                .to(mesh.material, { 
                opacity: 1,
                duration: mdDur, 
                delay: lgDur, 
                onUpdate: function() {
                  if (mesh.material.opacity < 1) {
                    mesh.material.depthTest = false;
                    gsap.set(mesh.material, {transparent: true})
                  } else {
                    mesh.material.depthTest = true;
                  }
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }   
                })  
                mesh.children.forEach((mesh) => {
                  if ((mesh.name === 'title' || mesh.name === 'logo') && mesh.name !== 'learn-more') {
                    mesh.material = copyMaterial(mesh)
                    mesh.material.transparent = true;
                    gsap
                    .to(mesh.material, {
                      opacity: 1,
                      duration: lgDur, 
                      delay: lgDur,
                      onUpdate: function() {
                        if (mesh.material.opacity < 1) {
                          mesh.material.depthTest = false;
                        } else {
                          mesh.material.depthTest = true;
                        }
                        if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                          mesh.material.visible = true
                        } else {
                          mesh.material.visible = false
                        }
                      }   
                    })
                  }
                })
            }
          })
        })
      })
      self.add("reverseTimeline", () => tl.reverse());
      return () => ctx.revert();
    }, component);

    context.current = ctx;

  //   return () => ctx.revert();
  // },[]);

  useEffect(() => {
    document.body.style.cursor = ((hovered === props.name && (props.name !== isActive || (props.name === 'active-platform' && isLevel === 1)) && props.dest !== '04')) ? 'pointer' : 'auto'    
  })

  const handleClick = (e) => { 

    if (isAnimating) return;
    // const isAnim = true;
    // setIsAnimating(isAnim);
    // console.log('isAnimating: ' + isAnimating);

    if ((activeRef.current === true && isActive !== props.name) || (activeRef.current === true && isActive === 'active-platform' && props.dest === '02')) {
      activeRef.current = false;
    } else if (activeRef.current === false) {
      return false;
    }
    activeRef.current = true;

    if (props.name === '' || props.dest === '04' || (isActive === props.name && isActive !== 'active-platform')) {
      return false;
    }
    if (isLevel === 2 && isActive === 'active-platform' && props.dest === '02') {
      return false;
    }
    if (props.name !== 'back') {
        active(props.name)
      if (props.dest === '02') {
        topCtx(context.current)
      } else {
        prev(context.current)
      }
      level(isLevel+1)
      // console.log(context.current)
      context.current.playTimeline()
    } else {
      // Back Click
      level(isLevel-1)
      if (props.dest === '02') {    
        active(prevActive)
      } else {
        if (props.dest === '01' && isActive !== 'active-platform') {
          active('')
        }        
      }
    }
    scene(props.dest)
    direction(props.isDirection)
    // console.log(e.eventObject);
    // console.log('click went through!');
  };

  // const meshColor = useControls({
  //   value: '#008ecb',
  // })
  // const meshMaterial = useControls({
  //   transmission: {
  //     value: 1.11,
  //     min: 0,
  //     max: 2,
  //     step: 0.05,
  //   },
  //   metalness: {
  //     value: 1,
  //     min: 0,
  //     max: 2,
  //     step: 0.05,
  //   },
  //   roughness: {
  //     value: 0,
  //     min: 0,
  //     max: 2,
  //     step: 0.05,
  //   },
  //   ior: {
  //     value: 1.25,
  //     min: 0,
  //     max: 2,
  //     step: 0.05,
  //   },
  // })

  // const lght = useControls({
  //   vec3: {
  //     x: 3.4,
  //     y: 7.5,
  //     z: -3.5,
  //   },
  //   decay: 2.5,
  //   intensity: 800,
  //   scale: 0.393
  // })

  let titleText
  let link
  // let light

  if (props.url !== undefined) {
    link = <Link 
        name="learn-more" 
        title="Learn More →" 
        isActive={isActive}
        currentHex={props.name}
        url={props.url}/>
  }
  // if (props.light !== undefined && props.light === true) {
    // console.log(lght)
    // light = <KeyLight name="addon" 
    // intensity={lght.intensity ? lght.intensity : 800} 
    // decay={lght.decay ? lght.decay : 2.5} 
    // position={lght.vec3 ? [lght.vec3.x,lght.vec3.y,lght.vec3.z] : [-3.4, 7.5, -3.5]} 
    // rotation={[-1.5, 0, 0]}
    // scale={lght.scale ? lght.scale : 0.393} />

  //   light = <group><KeyLight brightness={5.6} color={"#ffc9f9"} />
  //   <FillLight brightness={2.6} color={"#bdefff"} />
  //   <RimLight brightness={54} color={"#fff"} /></group>
  // }
  if (props.dest === '02' && props.name !== 'back') {
    titleText = <Text 
    name="title"
    fontSize={fontSize ? fontSize : 0.7 }
    position={titlePosition ? titlePosition : [0.05, 1,-1.158] }
    textAlign= "center"
    color={textColor ? textColor : 'white' }
    font={suspend(bold).default}
    anchorZ="center" 
    lineHeight={1.1} 
    rotation={[-1.5, 0, 0]}
    letterSpacing= {0}
    material-toneMapped={false}>
      {title}
    </Text>;
  } else if (props.name === 'back') {
    titleText = <Text 
    fontSize={fontSize ? fontSize : 0.4 }
    position={titlePosition ? titlePosition : [0.095, 1,0.25] }
    textAlign= "center"
    color={textColor ? textColor : 'white' }
    font={suspend(bold).default}
    anchorZ="center"
    anchorX="center" 
    lineHeight={1.1} 
    rotation={[-1.5, 0, 0]}
    letterSpacing= {0}
    material-toneMapped={false}>
      {title}
    </Text>;
  } else if (props.dest === '04') {
    titleText = <Text 
    name="title"
    fontSize={fontSize ? fontSize : 0.53 }
    position={titlePosition ? titlePosition : [0, .414,-0.01] }
    textAlign= "center"
    anchorZ="center"
    anchorX="center"
    color={textColor ? textColor : 'white' }
    font={suspend(bold).default}
    lineHeight={1.05} 
    rotation={[-1.5, 0, 0]}
    letterSpacing= {0}
    material-toneMapped={false}>
      {title}
    </Text>;  
     
  } else {
    titleText = <Text 
    name="title"
    fontSize={fontSize ? fontSize : 0.53 }
    position={titlePosition ? titlePosition : [0, .414, -.2] }
    textAlign= "center"
    anchorZ="center"
    color={textColor ? textColor : 'white' }
    font={suspend(bold).default}
    lineHeight={1.05} 
    rotation={[-1.5, 0, 0]}
    letterSpacing= {0}
    material-toneMapped={false}>
      {title}
    </Text>;    
  }


  // const env = useEnvironment({ preset: "studio" })

  return (
    // const color = new THREE.Color(meshColor.value);
    <group ref={component} {...props}>
      <Select enabled={(hovered === props.name && (props.name !== isActive || (props.name === 'active-platform' && isLevel === 1)) && props.dest !== '04')} 
        onPointerOver={over(props.name)} 
        // onPointerOver={(e) => handleOver(e)}
        // onPointerOver={(e) => (e.eventObject.visible === true) ? over(props.name) : ''}
        onPointerOut={() => debouncedHover(null)}>
        {/* {centerBegin}   */}
      <mesh
        dest={props.dest} 
        geometry={geometry}
        material={material}
        // for testing colors
        // material-color={meshColor.value}
        // material-transmission={meshMaterial.transmission}  
        // material-ior={meshMaterial.ior} 
        // material-metalness={meshMaterial.metalness} 
        // material-roughness={meshMaterial.roughness}
        // material-envMap={env} 
        // material-envMapIntensity={intensity ? intensity : 0.1}
        ref={activeRef}
        onClick={(e) => (e.eventObject.visible && (handleClick(e),e.stopPropagation()))}
        // onClick={(e) => (e.stopPropagation(), handleClick(e))} 
        material-toneMapped={false}
        >
          
        {titleText}
        
      <Text 
      fontSize={0.3}
      position={textPosition ? textPosition : [-2, .939, .86] }
      textAlign= "left"
      color={textColor ? textColor : 'white' }
      font={suspend(regular).default}
      anchorX="left" 
      lineHeight={1.5} 
      rotation={[-1.5, 0, 0]}
      letterSpacing= {0.05}
      extrude={.01}
      materialType= "MeshPhongMaterial"
      material-toneMapped={false}>
                {/* <mesh name="title" geometry={nodes.Supply_Chain_Execution.geometry} material={materials['White text']} position={[-.15, 1.35, -1.158]} scale={0.75} /> */}
        {text}
      </Text>
          {children}
          {link}
          {/* {light} */}
         
      </mesh>
  {/* {centerEnd} */}
      </Select>
    </group>
  )
}

export default Hex