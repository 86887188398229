import "./App.css";

import { useEffect, useRef, useState, Suspense } from 'react'
import { Canvas, extend, useFrame,useThree } from '@react-three/fiber'
import { easing } from "maath"
import { geometry } from 'maath'
import { Stage } from './Stage'
import { Model } from './Level1'
import { Model2 } from './Supply-chain-execution-lvl2'
import { ModelActive } from './Active-platform-lvl2'
import { ModelActiveComp } from './Active-platform-lvl3-comp-int'
import { ModelActiveBehav } from './Active-platform-lvl3-behav-int'
import { ModelActiveDev } from './Active-platform-lvl3-dev-exp'
import { ModelActiveData } from './Active-platform-lvl3-data-analytics'
import { Model3 } from './Supply-chain-execution-lvl3-warehouse'
import { ModelLabor } from './Supply-chain-execution-lvl3-labor'
import { ModelTransportation } from './Supply-chain-execution-lvl3-transportation'
import { ModelCarrier } from './Supply-chain-execution-lvl3-carrier'
import { Model4 } from './Supply-chain-planning-lvl2'
import { Model5 } from './Supply-chain-planning-lvl3-demand'
import { ModelAlloc } from './Supply-chain-planning-lvl3-allocation'
import { ModelRepl } from './Supply-chain-planning-lvl3-replenishment'
import { ModelOmni } from './Omnichannel-lvl2'
import { ModelOmniOrder } from './Omnichannel-lvl3-order'
import { ModelOmniPos } from './Omnichannel-lvl3-pos'
import { ModelOmniStore } from './Omnichannel-lvl3-store'
import { ModelOmniCustomer } from './Omnichannel-lvl3-customer'
import { EffectComposer, Selection, Outline, N8AO } from "@react-three/postprocessing"
import { BlendFunction } from 'postprocessing'
import { useGSAP } from "@gsap/react";

extend(geometry)

function App(props) {

  // const { camera, gl: renderer } = useThree();
  // const [mouse, setMouse] = useState({ x: 0, y: 0 });

  // useEffect(() => {

  // });
  // const handleWindowChange = () => {
  //   const width = window.innerWidth;
  //   const height = window.innerHeight;
  //   renderer.setSize(width, height);
  //   camera.aspect = width / height;
  //   camera.updateProjectionMatrix();
  // };

  // const handleMouseMove = (event) => {
  //   const rect = renderer.domElement.getBoundingClientRect();
  //   setMouse({
  //     x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
  //     y: -((event.clientY - rect.top) / rect.height) * 2 + 1,
  //   });
  // };

  // window.addEventListener('resize', handleWindowChange);
  // window.addEventListener('mousemove', handleMouseMove);

  // // Initial call to handleWindowChange to set the correct size on load
  // handleWindowChange();
  const [pointerEvents, setPointerEvents] = useState('auto');
  const [anim, setIsAnim] = useState(false);

  const [direction, setDirection] = useState('');
  const [prev, setPrev] = useState();
  const [scene, setScene] = useState('01');
  const [active, setActive] = useState('');
  const [topSceneCtx, setTopSceneCtx] = useState('');
  const [sceneCtx, setSceneCtx] = useState();
  const [topCtx, setTopCtx] = useState();
  const [stageCtx, setStageCtx] = useState();

  const prevValueRef = useRef();
  const prevSceneRef = useRef();

  const prevTopSceneRef = useRef();

  const prevTopValueRef = useRef();
  const prevStageRef = useRef();

  const [prevActive, setPrevActive] = useState();
  const prevActiveRef = useRef();
  const [level, setLevel] = useState(1)

  const app = useRef();

  useEffect(() => {
    prevActiveRef.current = active;
    prevValueRef.current = prev;
    prevSceneRef.current = sceneCtx;
    prevTopSceneRef.current = topSceneCtx;
    prevTopValueRef.current = topCtx;
    prevStageRef.current = stageCtx;

  }, [prev,sceneCtx,topCtx,topSceneCtx,active,stageCtx]);

  useGSAP(() => {
    setPrevActive(prevActiveRef.current)
    if (scene === '01' || scene === '') {
      // console.log('Top level')

      if (direction === 'up') {
        prevTopSceneRef?.current.reverseTimeline()
        prevTopValueRef?.current.reverseTimeline()  
        prevSceneRef?.current.reverseTimeline()

        if (active === 'active-platform') {
          // console.log('lets reverse! active platform')
          prevStageRef?.current.flipHexesRev()
          prevStageRef?.current.animateTextRev()
          prevTopSceneRef.current.shiftLightsActiveRev()
        } else {
          prevTopSceneRef.current.shiftLightsRev()
          prevStageRef?.current.animateSmallHexes()
        }
        if (active !== 'supply-chain-planning') {
          prevTopSceneRef?.current.supplyChainPlanningRev()
        }
        if (active !== 'supply-chain-execution') {
          prevTopSceneRef?.current.supplyChainExecutionRev()
        }
        if (active !== 'omnichannel') {
          prevTopSceneRef?.current.omniChannelRev()
        }
        if (active !== 'active-platform') {
          prevTopSceneRef?.current.activePlatformRev()
        }

      }
    } else if (scene === '02') {
      // console.log('2nd level')
      // console.log(active)
      if (direction === 'down') {
        // loading a snapshot and excluding the active one @todo: make this more automated

        if (active !== 'omnichannel') {
          prevTopSceneRef.current.omniChannel()
        }
        if (active !== 'supply-chain-planning') {
          prevTopSceneRef.current.supplyChainPlanning()
        }
        if (active !== 'supply-chain-execution') {
          prevTopSceneRef.current.supplyChainExecution()
        }
        if (active !== 'active-platform') {
          prevTopSceneRef.current.activePlatform()
        }        
        prevTopSceneRef.current.playTimeline()

        // console.log(prevTopSceneRef.current)

        if (active === 'active-platform') {
          prevStageRef?.current.flipHexes()
          prevStageRef?.current.animateText()
          prevTopSceneRef.current.shiftLightsActive()
        } else {
          prevTopSceneRef.current.shiftLights()
          prevStageRef?.current.animateSmallHexes()
        }
      } else {
        prevValueRef?.current.reverseTimeline()
        prevSceneRef?.current.reverseTimeline()
        prevTopValueRef?.current.showPrevious()
        prevStageRef?.current.animateSmallHexes()
        // console.log(active);
        if (active === 'active-platform') {
          prevTopSceneRef?.current.shiftLightsLvl3ActiveRev()
        } else {
          prevTopSceneRef?.current.shiftLightsLvl3Rev()
        }
        // prevValueRef?.current.hideLearnMore()
      }
    } else if (scene === '03') {
      // console.log('3rd level')
      prevStageRef.current.animateSmallHexes()
      prevTopValueRef.current.hidePreviousTop()
      if (direction === 'down') {
        if (prevActiveRef.current === 'active-platform') {
          prevTopSceneRef.current.shiftLightsLvl3Active()
        } else {
          prevTopSceneRef.current.shiftLightsLvl3()
        }
      }
      // prevTopValueRef?.current.showLearnMore()
    }
    // prevTopSceneRef,prevTopValueRef,prevSceneRef,prevValueRef,
  }, { scope: app, dependencies: [active,direction,scene,prevValueRef]});
  // const { camera, size, viewport } = useThree();
  return (
    // <div className="App" style={{ width: "100%", height: "1200px" }}>
    <div className="canvas-container"
    style={{
      pointerEvents: anim
    }}
    >
      <Suspense fallback={<div id="loader"></div>}>
      <Canvas 
      ref={app}
      camera={{ fov: 50, position: [0, 22, 1],focus:[0, 0, 0] }} 
      // eventSource={document.getElementById('manhattan3d-app')} 
      // eventPrefix="client"
      // pixelRatio={Math.min(window.devicePixelRatio, 2)}
      >        
    <group >
      <Selection>
        <Model name="01" title="top" position={[0, 10, -5]}
        scene={setScene}
        direction={setDirection}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        isActive={active} 
        isScene={scene}
        isDirection={direction}     
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        topSceneCtx={setTopSceneCtx}
        topCtx={setTopCtx}
        level={setLevel}
        isLevel={level}
        />
        <Model2 name="02" title="supply-chain-execution" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}           
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <ModelActive name="02" title="active-platform" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}           
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <ModelActiveComp name="03" title="computational-intelligence" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}  
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <ModelActiveBehav name="03" title="behavioral-intelligence" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}  
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <ModelActiveDev name="03" title="developer-experience" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}  
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <ModelActiveData name="03" title="data-analytics" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}  
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 


        <Model3 name="03" title="warehouse-management" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction} 
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <ModelLabor name="03" title="labor-management" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        isActive={active}
        direction={setDirection}
        isDirection={direction}  
        isScene={scene}
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <ModelTransportation name="03" title="transportation-management" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction} 
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        />

        <ModelCarrier name="03" title="carrier-management" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction} 
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        />
        
        <Model4 name="02" title="supply-chain-planning" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}  
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <Model5 name="03" title="demand-forecasting" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction} 
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <ModelRepl name="03" title="replenishment" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}  
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <ModelAlloc name="03" title="allocation" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction} 
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        />    
        <ModelOmni name="02" title="omnichannel" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}  
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <ModelOmniOrder name="03" title="order-management" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}  
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        /> 
        <ModelOmniPos name="03" title="point-of-sale" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}  
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        />
        <ModelOmniStore name="03" title="store-inventory-fulfillment" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}  
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        />
        <ModelOmniCustomer name="03" title="customer-service-engagement" position={[0, 10, -5]}
        scene={setScene}
        active={setActive}
        anim={setIsAnim}
        isAnim={anim}
        direction={setDirection}
        isActive={active}
        isScene={scene}
        isDirection={direction}  
        prev={setPrev}
        prevActive={prevActive}
        sceneCtx={setSceneCtx}
        level={setLevel}
        isLevel={level}
        />
        <Stage position={[0, -5, -5]}
        stageCtx={setStageCtx} 
        />
        {/* <Environment preset="studio" blur={0.6} />    */}
        {/* <Rig /> */}
        <Effects/>

        </Selection>        
        </group> 
      </Canvas>
      </Suspense>
    </div>
  )
}

function Effects() {
  useFrame((state, delta) => {
    const invertedMovementX = (state.pointer.x - 0.5) * 0.1;
    const invertedMovementY = (state.pointer.y - 0.5) * 0.1;
    const shiftAmt = 8;

    // 900px
    // 880px
    easing.damp3(state.camera.position, [
      invertedMovementX * shiftAmt, 
      22,  
      -4 + -invertedMovementY * shiftAmt], 
      0.3, delta)
    // state.camera.lookAt(state.camera.position.x * 0.9, -12, -4)
  })
  return (
    <EffectComposer stencilBuffer disableNormalPass autoClear={false} multisampling={4}>
    <N8AO halfRes aoSamples={5} aoRadius={0.4} distanceFalloff={0.75} intensity={1} />
    <Outline   
      blendFunction={BlendFunction.ALPHA}          
      polygonOffset
      polygonOffsetFactor={40} 
      thickness={8} 
      opacity={0.8}
      xRay={true} 
      visibleEdgeColor="#9ca6e4" 
      // play with opacity here
      hiddenEdgeColor="#3a424f"
      // kernelSize={KernelSize.SMALL} 
      blur 
      edgeStrength={10} />
    {/* <TiltShift2 samples={5} blur={0.1} /> */}
    {/* <ToneMapping /> */}
    </EffectComposer>
  )
}

export default App;
