import { useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { Text } from '@react-three/drei'
import { suspend } from 'suspend-react'
import * as THREE from 'three'
import { useGSAP } from "@gsap/react";
import { easing } from "maath"
const bold = import('../../assets/HelveticaNowDisplayXBd.ttf')

function Link({geometry, material, children, ...props}) {

  const [hover, hovered] = useState(false)
  const title = props.title 
  const url = props.url 
  const titlePosition = props.titlePosition
  const fontSize = props.fontSize

  const component = useRef();

  function copyMaterial(mesh) {
    if (mesh.material !== undefined) {
      let prevMaterial = mesh.material; 
      let material
      if (mesh.material.type === 'MeshPhysicalMaterial') {
        material = new THREE.MeshPhysicalMaterial();          
        THREE.MeshPhysicalMaterial.prototype.copy.call( material, prevMaterial );
      } else {
        material = new THREE.MeshBasicMaterial();          
        THREE.MeshBasicMaterial.prototype.copy.call( material, prevMaterial );
      }
      return material
    }    
  }

  const handleClick = (e) => {
    // console.log('clicked!')
    if(e.eventObject.parent.visible === true) {
      // console.log('visible');
      // console.log(e.eventObject.parent)
      // const newWindow = window.open(props.url, '_blank', 'noopener,noreferrer')
      const newWindow = window.open(url, '_self', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  };

  useGSAP(() => {
    component.current.visible = false
    component.current.children[0].children.forEach((mesh) => {
      if (mesh.isMesh) {
          mesh.material = copyMaterial(mesh)
          mesh.material.opacity = 0
          mesh.material.transparent = true
        }
    })
  }, { scope: component });

  useFrame((state, dt) => {   
    if (props.currentHex === props.isActive) {
      document.body.style.cursor = hover ? 'pointer' : 'auto' 
      easing.damp(component.current.children[0].children[0].material, 'opacity', hover ? 0.6 : 1 , 0.2, dt)
    } else {
      component.current.children[0].children[0].material.opacity = 0;
    }
  })
  return (
    <group ref={component} {...props}>
      <mesh 
        onPointerOver={(e) => (e.eventObject.parent.visible && (hovered(true)))}
        onPointerOut={(e) => (e.eventObject.parent.visible && hovered(false))}
        // onClick={(e) => (e.stopPropagation(), handleClick(e))} 
        // only pass the click handler if item is visible
        onClick={(e) => (e.eventObject.parent.visible && (handleClick(e)))}
        >
        <Text 
        fontSize={fontSize ? fontSize : 0.32 }
        position={titlePosition ? titlePosition : [0.04, 1.2,1.123] }
        textAlign= "center"
        color={'white'}
        font={suspend(bold).default}
        anchorZ="center"
        anchorX="center" 
        lineHeight={1.1} 
        rotation={[-1.5, 0, 0]}
        letterSpacing= {0}
        material-toneMapped={false}>
          {title}
        </Text>
      </mesh>

    </group>
  )
}

export default Link